import { GoogleTagManager, GoogleAnalytics } from '@next/third-parties/google'

interface GTagProps {
  gtmId: string
  gaId: string
}

// @see https://developers.google.com/tag-platform/gtagjs

export function GTag(props: GTagProps): JSX.Element {
  const { gtmId, gaId } = props

  return (
    <>
      <GoogleAnalytics gaId={gaId} />
      <GoogleTagManager gtmId={gtmId} />
    </>
  )
}
