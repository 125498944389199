const domainName = process.env.NEXT_PUBLIC_DOMAIN_NAME

const isProduction = !domainName?.endsWith('.mapstack.io')

const team = {
  name: 'team',
  feeDescription: 'per month',
  currency: '$',
  highlight: 'Most popular',
  includesTitle: 'Everything in free, plus:',
  includes: [
    '10,000 records for Team',
    '100 guests for Team',
    'Census data',
    'Point of interest data'
  ],
  recordsLimit: 25_000,
  guestsLimit: 100,
  description:
    'Designed for growing teams that need to collaborate and share insights with ease.'
}

const business = {
  name: 'business',
  feeDescription: 'per month',
  currency: '$',
  includesTitle: 'Everything in team, plus:',
  includes: [
    '100,000 records for business',
    '250 guests for business',
    'Premium geocoding',
    'Satellite imagery'
  ],
  recordsLimit: 100_000,
  guestsLimit: 250,
  description:
    'Ideal for organizations managing larger datasets and requiring advanced tools.'
}

export const PLANS = {
  free: {
    name: 'free',
    id: '',
    recordsLimit: 1_000,
    guestsLimit: 10,
    description:
      'Perfect for individuals and small projects looking to explore Mapstack’s core features.',
    includesTitle: 'Free plan includes',
    includes: [
      'Unlimited maps',
      'Unlimited geocodes',
      '1000 records for free',
      '10 guests for free'
    ]
  },
  team,
  teamMonthly: {
    price: 24,
    ...team,
    id: isProduction
      ? 'price_1QfNX3Dek6HyW6KPptkkxa6O'
      : 'price_1QRFO0Dek6HyW6KPH4RXhcuh'
  },
  teamYearly: {
    price: 228,
    ...team,
    id: isProduction
      ? 'price_1QfNX3Dek6HyW6KPFbHsjwCu'
      : 'price_1QXn83Dek6HyW6KPZsHlKrNA'
  },
  business,
  businessMonthly: {
    price: 49,
    ...business,
    id: isProduction
      ? 'price_1QfNWzDek6HyW6KPTxbeRvVU'
      : 'price_1QXn83Dek6HyW6KPZsHlKrNA'
  },
  businessYearly: {
    price: 468.0,
    ...business,
    id: isProduction
      ? 'price_1QfNWzDek6HyW6KP3cfmFZQt'
      : 'price_1QXnLyDek6HyW6KPLuWCMQU5'
  },
  enterprise: {
    id: 'enterprise',
    name: 'enterprise',
    price: 'Custom pricing',
    feeDescription: '',
    currency: '',
    description:
      'Tailored for large-scale operations with custom needs, integrations, and dedicated support.',
    includesTitle: 'Everything in business, plus:',
    includes: [
      '500,000 records per dataset',
      'Invite unlimited guests',
      'Advanced security & controls',
      'SAML SSO',
      'Audit log'
    ],
    recordsLimit: Infinity,
    guestsLimit: Infinity
  }
}

export const getLimits = (workspace) => {
  if (!workspace?.plan) return PLANS.free.recordsLimit
  return PLANS[workspace.plan].recordsLimit || PLANS.free.recordsLimit
}
