import { useEffect } from 'react'

import { homePageView } from 'tag-manager/page_view'
import { Home as App } from 'web-pages'
import { homeSSR as getServerSideProps } from 'web-pages/ssr'

const Home = (props) => {
  useEffect(() => {
    homePageView()
  }, [])

  return <App />
}

export { getServerSideProps }
export default Home
