import {
  Avatar,
  Box,
  Card,
  Chip,
  Container,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import NextLink from 'next/link'
import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'

import { getWorkspaceLink } from 'utils'
import { Pagination } from '~ui-components/components/atoms/Pagination'
import { Footer } from '~ui-components/components/organisms/Footer'

const Empty = () => null

const defaultProps = {
  Header: Empty,
  WorkspaceListTable: Empty,
  Pagination
}

export function Workspaces(props) {
  const { slots: Slots = defaultProps, slotProps } = props

  return (
    <>
      <Slots.Header />
      <Stack
        component='main'
        sx={{
          pt: 8,
          overflow: 'hidden',
          '&:before': {
            content: "''",
            display: 'block',
            height: '70px'
          }
        }}
      >
        <Container maxWidth='xl'>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Typography variant='h4'>Workspaces</Typography>
            </Stack>

            <Card>
              <Slots.WorkspaceListTable {...slotProps?.WorkspaceListTable} />
            </Card>

            <Stack alignItems='center'>
              <Slots.Pagination {...slotProps?.Pagination} />
            </Stack>
          </Stack>
        </Container>
      </Stack>
      <Footer />
    </>
  )
}

export function WorkspaceListTable(props) {
  const { items = [] } = props

  return (
    <div>
      <Scrollbar>
        <Table sx={{ minWidth: 1200 }}>
          <TableHead>
            <TableRow>
              <TableCell width='30%'>Name</TableCell>
              <TableCell width='50%'>Description</TableCell>
              <TableCell width='20%'>Maps</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <WorkspaceListItem
                key={item.id}
                {...item}
              />
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </div>
  )
}

function WorkspaceListItem(props) {
  const { id, name, description, logo, mapCollection = 0 } = props
  return (
    <TableRow hover>
      <TableCell>
        <Link
          component={NextLink}
          href={getWorkspaceLink({ workspaceId: id, workspaceName: name })}
          color='inherit'
          underline='hover'
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Avatar
            src={logo}
            alt=''
          />
          <Box
            sx={{
              cursor: 'pointer',
              ml: 2
            }}
          >
            <Typography variant='subtitle2'>{name}</Typography>
          </Box>
        </Link>
      </TableCell>
      <TableCell>
        <Typography
          variant='body2'
          color='text.secondary'
        >
          {description}
        </Typography>
      </TableCell>
      <TableCell>
        <Chip
          label={mapCollection?.total || 0}
          variant='filled'
          color='default'
          size='small'
        />
      </TableCell>
    </TableRow>
  )
}
