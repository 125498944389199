import {
  Box,
  Grid,
  IconButton,
  Link as MuiLink,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'

import NextLink from 'next/link'
import {
  getPrivacyLink,
  getTagsLink,
  getTermsLink,
  getWorkspacesLink
} from 'utils'
import Facebook from '~ui-components/assets/svg/Facebook'
import Linkedin from '~ui-components/assets/svg/Linkedin'
import Twitter from '~ui-components/assets/svg/Twitter'

import { Mapstack } from '~ui-components/assets/svg/logos/Mapstack'
import Container from '~ui-components/components/atoms/Container'

const Footer = (props) => {
  return (
    <Box
      component='footer'
      {...props}
    >
      <Container position='relative'>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
              flexDirection={{ xs: 'column', sm: 'row' }}
            >
              <Box
                display='flex'
                title='mapstack'
                height={24}
                width={35}
              >
                <Mapstack
                  height='100%'
                  width='100%'
                />
              </Box>

              <Stack
                direction='row'
                spacing={1}
                pt={{ xs: 2 }}
              >
                <Social
                  label='Twitter'
                  href='https://twitter.com/mapstack_io'
                  icon={
                    <SvgIcon>
                      <Twitter />
                    </SvgIcon>
                  }
                />

                <Social
                  label='LinkedIn'
                  href='https://www.linkedin.com/company/mapstack/'
                  icon={
                    <SvgIcon>
                      <Linkedin />
                    </SvgIcon>
                  }
                />

                <Social
                  label='Facebook'
                  href='https://www.facebook.com/mapstack.io'
                  icon={
                    <SvgIcon>
                      <Facebook />
                    </SvgIcon>
                  }
                />
              </Stack>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Typography
              textAlign='center'
              variant='caption'
              color='text.secondary'
              component='p'
              gutterBottom
            >
              ©2023 Mapstack. 1 Victoria Street, Bristol, England, BS1 6AA, UK
            </Typography>

            <Typography
              align='center'
              variant='caption'
              color='text.secondary'
              component='p'
            >
              When you visit or interact with our sites, services or tools, we
              or our authorised service providers may use cookies for storing
              information to help provide you with a better, faster and safer
              experience and for marketing purposes.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            display='flex'
            flexDirection='row'
            justifyContent='center'
            alignItems='center'
            textAlign='center'
            flexWrap='wrap'
            gap={2}
          >
            <Link
              label='Privacy policy'
              href={getPrivacyLink()}
            />

            <Link
              label='Terms of service'
              href={getTermsLink()}
            />

            <Link
              label='Workspaces'
              href={getWorkspacesLink()}
            />

            <Link
              label='Tags'
              href={getTagsLink(1)}
            />

            <Link
              label='Contact us'
              href='mailto:support@mapstack.io'
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

function Social(props) {
  const { label, href, icon } = props
  return (
    <IconButton
      component={NextLink}
      key={label}
      title={label}
      href={href}
      size='small'
    >
      {icon}
    </IconButton>
  )
}

function Link(props) {
  const { label, href, ...rest } = props
  return (
    <Box>
      <MuiLink
        component={NextLink}
        href={href}
        color='text.primary'
        variant='subtitle2'
        {...rest}
      >
        {label}
      </MuiLink>
    </Box>
  )
}

export { Footer }
