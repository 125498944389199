import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { VISUALISATION, withReduxConfigs } from 'utils'

import { map as _map, toNumber } from 'lodash'

import mapApi from '~map-viewer/services/map'

import filterReducer from '~map-filter-view/states/filter'
import errorReducer from './error'
import layerReducer from './layer'
import likeReducer from './like'
import mapReducer, { initialMapState } from './map'
import remixReducer from './remix'
import uiReducer, { initialUIState } from './ui'
import visualisationReducer from './visualisation'

const appReducer = combineReducers({
  error: errorReducer,
  filter: filterReducer,
  layer: layerReducer,
  map: mapReducer,
  ui: uiReducer,
  like: likeReducer,
  visualisation: visualisationReducer,
  remix: remixReducer,
  [mapApi.reducerPath]: mapApi.reducer
})

const rootReducer = (state, action) => {
  if (action.type === 'map/clearMapData') {
    state = {}
  }
  return appReducer(state, action)
}

let store

export const createStore = (initialState) => {
  store = configureStore(
    withReduxConfigs({
      reducer: rootReducer,
      middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat([mapApi.middleware])
      },
      preloadedState: {
        map: {
          ...initialMapState,
          mapData: initialState?.map ?? {}
        },
        visualisation: getDefaultVisualisation(initialState?.map),
        ui: {
          ...initialUIState,
          href: initialState?.href
        }
      }
    })
  )

  return store
}

export { store }

const getDefaultVisualisation = (map) => {
  if (!map?.properties) return {}

  const { properties } = map

  const defaultVisualisation = properties.find(
    (property) => property.isDefaultVisualisation && property.type === 'number'
  )

  const property = defaultVisualisation || {}

  const { name, classBreaks, visualisation: type } = property

  const mappedClassBreaks =
    type === VISUALISATION.QUANTITY ? _map(classBreaks, toNumber) : classBreaks

  return {
    value: name,
    classBreaks: mappedClassBreaks,
    type
  }
}
