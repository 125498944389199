import { GraphQLOptionsV6 } from '@aws-amplify/api-graphql'
import { GraphQLResult } from 'aws-amplify/api'
import { client } from './client'

type Options<Args = Record<string, unknown>> = Omit<
  GraphQLOptionsV6,
  'variables'
> & {
  variables?: Args
}

export const graphql = async <Results, Args>(options: Options<Args>) => {
  const request = client.graphql(options as any) as Promise<
    GraphQLResult<Results>
  >
  return request.then((result) => result.data)
}
