import bbox from '@turf/bbox'
import buffer from '@turf/buffer'

export const getBoundingBoxData = (geojson) => {
  return bbox(geojson)
}

export const getPointBoundingBoxData = (geojson) => {
  const buffered = buffer(geojson, 1, { units: 'kilometers' }) as any
  return bbox(buffered)
}
