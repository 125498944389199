import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { fetchAuthSession } from 'aws-amplify/auth'
import { graphql } from 'ms-client/graphql/api-key'
import {
  Query,
  QueryGetUserByIdArgs
} from '~user-auth/types/__generated/gql/graphql'

export const getUserByIdQuery = /* GraphQL */ `
  query GetUserById($id: ID!) {
    getUserById(id: $id) {
      id
      uid
      firstName
      lastName
      email
      avatar
      createdAt
      defaultWorkspaceId
      defaultWorkspace {
        id
        name
        description
        logo
        plan
        email
        twitterUsername
        inviteId
        stripeCustomerId
        websiteUrl
        communityUrl
        createdAt
        updatedAt
      }
      membershipCollection {
        total
        items {
          role
          workspace {
            id
            name
            description
            logo
            plan
            email
            twitterUsername
            inviteId
            stripeCustomerId
            websiteUrl
            communityUrl
            createdAt
            updatedAt
            memberCollection {
              total
              items {
                role
                user {
                  id
                  firstName
                  lastName
                  email
                  avatar
                }
              }
            }
            guestCollection {
              total
              items {
                role
                user {
                  id
                  firstName
                  lastName
                  email
                  avatar
                }
              }
            }
          }
        }
      }
    }
  }
`

export type GetUserByIdResult = Pick<Query, 'getUserById'>

export const getUserByIdQueryKey = (id: string) => {
  return ['getUserById', id]
}

export const getUserById = async (id: string) => {
  const data = await graphql<GetUserByIdResult, QueryGetUserByIdArgs>({
    query: getUserByIdQuery,
    variables: { id }
  })
  return data?.getUserById
}

export const getCurrentUserIdKey = () => ['getCurrentUserId']

export const getCurrentUserTokenKey = () => ['getCurrentUserToken']

export const useGetCurrentUserId = () => {
  return useQuery({
    queryKey: getCurrentUserIdKey(),
    refetchOnMount: true,
    queryFn: async () => {
      const session = await fetchAuthSession()
      const userId = session?.tokens?.idToken?.payload?.['custom:id'] as unknown
      if (!userId) return null
      return userId as string
    }
  })
}

export const useGetUserById = (
  id?: string | null
): UseQueryResult<GetUserByIdResult['getUserById']> => {
  return useQuery({
    refetchOnMount: false,
    queryKey: getUserByIdQueryKey(id!),
    queryFn: () => getUserById(id!)
  })
}

export const useGetCurrentUserToken = () => {
  return useQuery({
    refetchOnMount: true,
    queryKey: getCurrentUserTokenKey(),
    queryFn: async () => {
      const session = await fetchAuthSession()
      return session.tokens?.idToken?.toString()
    }
  })
}
