import { createServerRunner } from '@aws-amplify/adapter-nextjs'
import { generateServerClientUsingReqRes } from '@aws-amplify/adapter-nextjs/api'
import { configure } from './configure'

const { runWithAmplifyServerContext } = createServerRunner({
  config: configure as any
})

const serverClient = generateServerClientUsingReqRes({
  config: configure
})

const createRunner = (context) => async (func) =>
  await runWithAmplifyServerContext({
    nextServerContext: { response: context.res, request: context.req },
    operation: func
  })

export { createRunner, runWithAmplifyServerContext, serverClient }
