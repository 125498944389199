import { useRouter } from 'next/router'
import { Search as SearchBar } from 'search-box'
import { AppNav } from 'ui-components'
import { useAuth } from 'user-auth'

import { CommunityItem } from './CommunityItem'
import { Logo } from './Logo'
import { MapCreateButton } from './MapCreateButton'
import { NavAvatar } from './NavAvatar'
import { NavDrawer } from './NavDrawer'
import { SignInButton } from './SignInButton'
import { SignUpButton } from './SignUpButton'

const Header = (props) => {
  const { hideSearch: hideSearchProp = false } = props
  const { authenticated } = useAuth()

  const { route } = useRouter()

  // prettier-ignore
  const hideSearchExact = ["/", "/solutions/geocoder"].some((value) => route === value)

  const hideSearchStarWith = [
    '/explore',
    '/workspace',
    '/user',
    '/pricing'
  ].some((value) => route.startsWith(value))

  return (
    <AppNav
      hideSearch={hideSearchExact || hideSearchStarWith || hideSearchProp}
      authenticated={authenticated}
      slots={{
        Logo,
        CommunityItem,
        MapCreateButton,
        SearchBar,
        NavAvatar,
        NavDrawer,
        SignInButton,
        SignUpButton
      }}
    />
  )
}

export { Header }
