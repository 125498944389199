import { Avatar, Box, Link, Stack, SvgIcon, Typography } from '@mui/material'
import Globe01 from '@untitled-ui/icons-react/build/esm/Globe01'
import Mail01 from '@untitled-ui/icons-react/build/esm/Mail01'
import MessageChatSquare from '@untitled-ui/icons-react/build/esm/MessageChatSquare'
import Twitter from '~ui-components/assets/svg/Twitter'

/**
 * A organism that display workspace info
 */
const WorkspaceInfo = (props) => {
  const { loading = false, workspace = {} } = props

  const {
    name,
    description,
    logo,
    websiteUrl,
    communityUrl,
    twitterUsername,
    email
  } = workspace

  return (
    <Stack
      data-testid='WorkspaceInfo'
      direction={{
        xs: 'column',
        md: 'row'
      }}
      gap={2}
      textAlign={{ xs: 'center', md: 'left' }}
      alignItems='center'
    >
      <Avatar
        alt={name ?? ''}
        src={logo ?? ''}
        sx={{ height: 100, width: 100, fontSize: 40 }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 2,
          alignItems: { xs: 'center', md: 'flex-start' }
        }}
      >
        <Typography
          color='text.secondary'
          variant='overline'
        >
          WORKSPACE
        </Typography>
        <Typography
          data-testid='WorkspaceInfoName'
          variant='h4'
          component='h1'
        >
          {name}
        </Typography>
        {description && (
          <Typography
            data-testid='WorkspaceInfoDescription'
            variant='body1'
          >
            {description}
          </Typography>
        )}
        <Box
          mt={{ xs: 1, md: 0.5 }}
          display='flex'
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'center', md: 'flex-start' }}
          gap={{ xs: '4px', md: '16px' }}
        >
          {websiteUrl && (
            <Box
              display='flex'
              alignItems='center'
            >
              <SvgIcon fontSize='small'>
                <Globe01 />
              </SvgIcon>
              &nbsp;
              <Typography
                data-testid='WorkspaceInfoWebsiteUrl'
                variant='subtitle2'
                component={Link}
                target='_blank'
                href={websiteUrl}
              >
                {websiteUrl.replace(/http:\/\/|https:\/\/|www./gi, '')}
              </Typography>
            </Box>
          )}
          {communityUrl && (
            <Box
              display='flex'
              alignItems='center'
            >
              <SvgIcon fontSize='small'>
                <MessageChatSquare />
              </SvgIcon>
              &nbsp;
              <Typography
                data-testid='WorkspaceInfoCommunityUrl'
                variant='subtitle2'
                component={Link}
                target='_blank'
                href={communityUrl}
              >
                Group chat
              </Typography>
            </Box>
          )}
          {twitterUsername && (
            <Box
              display='flex'
              alignItems='center'
            >
              <SvgIcon fontSize='small'>
                <Twitter />
              </SvgIcon>
              &nbsp;
              <Typography
                data-testid='WorkspaceInfoTwitterUsername'
                variant='subtitle2'
                component={Link}
                target='_blank'
                href={'https://twitter.com/' + twitterUsername}
              >
                {twitterUsername}
              </Typography>
            </Box>
          )}
          {email && (
            <Box
              display='flex'
              alignItems='center'
            >
              <SvgIcon fontSize='small'>
                <Mail01 />
              </SvgIcon>
              &nbsp;
              <Typography
                data-testid='WorkspaceInfoEmail'
                variant='subtitle2'
                component={Link}
                href={'mailto:' + email}
              >
                Email contact
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Stack>
  )
}

export { WorkspaceInfo }
