import NextLink from 'next/link'

import { Button, Link, Stack, Typography } from '@mui/material'

import { LoadingButton } from '@mui/lab'
import { BrandLogo } from '~ui-components/components/atoms/BrandLogo'

export function MapAccessRequest(props) {
  const { slots, slotProps } = props

  const Slots = {
    MapAccessRequestButton,
    MapAccessBackButton,
    MapAccessRequestAuth,
    ...slots
  }

  return (
    <Stack
      direction='column'
      spacing={4}
      alignItems='center'
      justifyContent='center'
      minHeight='100vh'
      px={2}
      mt='-80px'
    >
      <BrandLogo
        size='xl'
        variant='logoCopy'
      />
      <Typography
        variant='next:h3'
        fontWeight={700}
        textAlign='center'
      >
        No access to this map
      </Typography>
      <Typography
        variant='next:h6'
        fontWeight={500}
        textAlign='center'
      >
        You can access this page if someone approves your request.
      </Typography>

      <Stack
        direction='row'
        gap={2}
      >
        <Slots.MapAccessRequestButton {...slotProps?.MapAccessRequestButton} />
        <Slots.MapAccessBackButton {...slotProps?.MapAccessBackButon} />
      </Stack>
      <Slots.MapAccessRequestAuth {...slotProps?.MapAccessRequestAuth} />
    </Stack>
  )
}

export function MapAccessRequestButton(props) {
  return (
    <LoadingButton
      size='large'
      variant='contained'
      color='primary'
      disableElevation
      {...props}
    >
      Request access
    </LoadingButton>
  )
}

export function MapAccessBackButton(props) {
  return (
    <Button
      component={NextLink}
      size='large'
      color='inherit'
      disableElevation
      {...props}
    >
      Back to my maps
    </Button>
  )
}

export function MapAccessRequestAuth(props) {
  const { user, onSignInClick } = props

  return (
    <Stack
      direction='column'
      textAlign='center'
    >
      <Typography variant='next:subtitle2'>
        You're currently signed in as <b>{user?.email}</b>
      </Typography>
      <Typography variant='next:subtitle2'>
        You might need to{' '}
        <Link
          sx={{
            cursor: 'pointer'
          }}
          color='secondary'
          underline='always'
          onClick={(event) => {
            event.preventDefault()
            onSignInClick?.()
          }}
        >
          sign in
        </Link>{' '}
        with a different email.
      </Typography>
    </Stack>
  )
}
