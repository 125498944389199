import jscookie from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { MapstackLoadingBackdrop, WORKSPACE_TAB } from 'ui-components'
import { fromBase64, getSearchLink, getWorkspaceLink } from 'utils'

import { useAuth } from '~user-auth/contexts/auth'
import { postSignInRedirect } from '~user-auth/storages/postSignInRedirect'

class UndefinedRedirect extends Error {
  constructor(params?) {
    super(params)
    this.name = 'UndefinedRedirect'
    this.message = 'Could not determine where to redirect user'
  }
}

class UndefinedWorkspace extends Error {
  constructor(params?) {
    super(params)
    this.name = 'UndefinedWorkspace'
  }
}

function AuthCallback() {
  const router = useRouter()
  const { ready, user } = useAuth()

  const gotoRedirect = () => {
    const redirect = postSignInRedirect.get()

    if (!redirect) throw new UndefinedRedirect()

    window.location.href = redirect
    postSignInRedirect.remove()
  }

  const gotoCurrentWorkspace = () => {
    const currentWorkspace = fromBase64(
      jscookie.get('workspace_current') || undefined
    )

    if (!currentWorkspace) throw new UndefinedWorkspace()

    window.location.href = getWorkspaceLink({
      workspaceId: currentWorkspace.id,
      workspaceName: currentWorkspace.name,
      tab: WORKSPACE_TAB.MAPS
    })
  }

  const gotoDefaultWorkspace = () => {
    if (!user?.defaultWorkspace) throw new UndefinedWorkspace()

    window.location.href = getWorkspaceLink({
      workspaceId: user.defaultWorkspace.id,
      workspaceName: user.defaultWorkspace.name,
      tab: WORKSPACE_TAB.MAPS
    })
  }

  const gotoSearch = () => {
    router.replace(getSearchLink())
  }

  useEffect(() => {
    if (!ready) return

    try {
      gotoRedirect()
      return
    } catch (error) {
      // do nothing
    }

    try {
      gotoCurrentWorkspace()
      return
    } catch (error) {
      // do nothing
    }

    try {
      gotoDefaultWorkspace()
      return
    } catch (error) {
      // do nothing
    }

    gotoSearch()
  }, [ready])

  return <MapstackLoadingBackdrop text='Redirecting...' />
}

export { AuthCallback }
