import jscookie from 'js-cookie'

export const useAuthMetadata = () => {
  const str = getMetadata()
  const metadata = str ? JSON.parse(fromBase64(str)) : {}
  return metadata
}

const fromBase64 = (str: string) => Buffer.from(str, 'base64').toString('utf-8')
const getMetadata = () => jscookie.get('auth_metadata')
