import { LogoutButton as Button } from 'ui-components'
import { useFederatedAuth } from 'user-auth'

export function LogoutButton() {
  const { signOut } = useFederatedAuth()

  const handleClick = () => {
    signOut({ preventRedirect: true })
  }

  return <Button onClick={handleClick} />
}
