import { GraphQLOptionsV6 } from '@aws-amplify/api-graphql'
import { GraphQLResult } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'
import { client } from './client'

type Options<Args = Record<string, unknown>> = Omit<
  GraphQLOptionsV6,
  'variables'
> & {
  variables?: Args
}

export const graphql = async <Results, Args>(options: Options<Args>) => {
  const session = await fetchAuthSession()
  if (!session) {
    console.warn(`Unable to get current session`)
    throw new Error('Session is unavailable')
  }

  const token = (await fetchAuthSession()).tokens?.idToken?.toString()
  if (!token) {
    console.warn('Unable to get token from current session')
    throw new Error('Authorization token is missing')
  }

  options.authMode = 'userPool'
  options.authToken = token

  const request = client.graphql(options as any) as Promise<
    GraphQLResult<Results>
  >

  return request.then((result) => result.data)
}
