import { LoadingButton } from '@mui/lab'
import {
  Box,
  CardMedia,
  FormHelperText,
  MenuItem,
  Stack,
  StackProps,
  TextField,
  Typography
} from '@mui/material'
import { Image01 } from '@untitled-ui/icons-react/build/esm'
import { useFormik } from 'formik'
import { FC } from 'react'

type SavePointMapInput = {
  name: string
  thumbnail: string
  workspaceId: string
}

export type PointMapFormProps = StackProps & {
  form: ReturnType<typeof useFormik<SavePointMapInput>>
  workspaces?: any[]
  slots?: any
}

const PointMapForm: FC<PointMapFormProps> = (props) => {
  const { form, workspaces, slots, ...rest } = props

  return (
    <Stack
      spacing={2}
      {...rest}
    >
      <TextField
        autoFocus
        label='Map name'
        name='name'
        value={form?.values?.name}
        onChange={form?.handleChange}
        onBlur={form?.handleBlur}
        error={!!form?.errors?.name}
        helperText={form?.errors?.name}
      />

      {workspaces && workspaces.length > 0 && (
        <TextField
          select
          disabled
          label='Workspace'
          name='workspaceId'
          value={form?.values?.workspaceId}
          onChange={form?.handleChange}
          onBlur={form?.handleBlur}
          error={!!form?.errors?.workspaceId}
          helperText={form?.errors?.workspaceId}
          SelectProps={{
            MenuProps: {
              sx: {
                zIndex: (theme) => theme.zIndex.modal + 4
              }
            }
          }}
        >
          {workspaces.map((workspace) => {
            return (
              <MenuItem
                key={workspace.id}
                value={workspace.id}
              >
                {workspace.name}
              </MenuItem>
            )
          })}
        </TextField>
      )}

      <Stack gap={1}>
        <Typography
          color='text.secondary'
          variant='overline'
        >
          Map thumbnail
        </Typography>
        <CardMedia
          image={form?.values?.thumbnail}
          sx={{
            width: '100%',
            aspectRatio: '1/1',
            border: (theme) => `1px solid ${theme.palette.neutral[200]}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 1
          }}
        >
          {form?.values?.thumbnail ? null : <Image01 />}
        </CardMedia>

        {!!form?.errors?.thumbnail && (
          <FormHelperText error>{form?.errors?.thumbnail}</FormHelperText>
        )}

        <FormHelperText>
          Zoom and pan the main map to reposition the thumbnail image.
        </FormHelperText>
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {slots && (
          <Box sx={{ alignSelf: 'flex-start' }}>
            <slots.DeleteButton />
          </Box>
        )}
        <LoadingButton
          variant='contained'
          loading={form?.isSubmitting}
          disabled={!form?.isValid}
          onClick={() => form?.submitForm()}
          sx={{ alignSelf: 'flex-end' }}
        >
          Save
        </LoadingButton>
      </Box>
    </Stack>
  )
}

export { PointMapForm }
