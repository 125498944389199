import {
  Box,
  Button,
  Stack,
  Tooltip,
  buttonClasses,
  lighten,
  Avatar as MuiAvatar
} from '@mui/material'
import NextLink from 'next/link'
import { FC } from 'react'

import { MaskLink } from '~ui-components/components/atoms/MaskLink'

const styles = {
  image: {
    width: 24,
    height: 24
  },
  text: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 'medium'
  }
}

const Avatar = ({ src, href, alt, ...props }) => {
  return (
    <NextLink
      href={href}
      passHref
      legacyBehavior
    >
      <Box
        {...props}
        data-testid={props?.testid}
        component='a'
        display='block'
        position='relative'
        sx={{ zIndex: 'aboveMaskLink' }}
      >
        <MuiAvatar
          alt={alt}
          src={src}
          sx={{
            width: '100%',
            height: '100%'
          }}
        />
      </Box>
    </NextLink>
  )
}

type TextProps = {
  text: string
  dotted?: boolean
}

const Text: FC<TextProps> = ({ text, dotted }) => {
  return (
    <Box
      sx={[
        {
          '&:before': {
            mx: 0.5
          }
        },
        dotted
          ? {
              '&:before': {
                content: "'•'"
              }
            }
          : {}
      ]}
    >
      {text}
    </Box>
  )
}

type AvatarProfileProps = {
  src: string
  href: string
  alt: string
  primaryText: string
  secondaryText?: string | string[]
}

const AvatarProfile: FC<AvatarProfileProps> = (props) => {
  const { src, href, alt, primaryText, secondaryText } = props

  return (
    <Stack
      direction='row'
      alignItems='center'
    >
      <Box
        component={Button}
        variant='text'
        color='inherit'
        data-testid='AvatarProfile'
        position='relative'
        startIcon={
          <Avatar
            alt={alt}
            src={src}
            href={href}
            {...styles.image}
          />
        }
        sx={[
          {
            px: 1.25,
            py: 0.5,
            mx: -0.75,
            my: -0.5,
            borderRadius: 1,
            fontWeight: 'medium',
            justifyContent: 'flex-start',
            [`.${buttonClasses.startIcon}`]: { ml: 0 }
          }
        ]}
      >
        <Tooltip
          title={primaryText}
          placement='top'
        >
          <Box
            {...styles.text}
            color='text.secondary'
            sx={(theme) => ({
              position: 'relative',
              zIndex: 'aboveMaskLink',
              '&:hover': {
                color: lighten(
                  theme.palette.text.primary,
                  theme.palette.action.hoverOpacity
                )
              },
              flex: 1,
              wordBreak: 'break-all',
              maxWidth: 'fit-content',
              display: '-webkit-box',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              cursor: 'pointer'
            })}
          >
            {primaryText}
            <MaskLink href={href} />
          </Box>
        </Tooltip>
      </Box>

      <Stack
        {...styles.text}
        color='text.secondary'
        direction='row'
      >
        {typeof secondaryText === 'string' ? (
          <>
            <Text text={secondaryText} />
          </>
        ) : Array.isArray(secondaryText) ? (
          <>
            {secondaryText?.map((text, index) => (
              <Text
                key={text}
                text={text}
                dotted
              />
            ))}
          </>
        ) : null}
      </Stack>
    </Stack>
  )
}

export { Avatar, AvatarProfile }
export default AvatarProfile
