import { BoundaryNameLookUp } from '../../../get-boundary-lookup'

// Define the individual country-specific objects
const AUBoundaryNames: BoundaryNameLookUp = {
  'MS_au-states': {
    singular: 'State',
    plural: 'States'
  },
  'MS_au-gdas': {
    singular: 'Local Government Area',
    plural: 'Local Government Areas'
  },
  'MS_au-postcodes': {
    singular: 'Postcode',
    plural: 'Postcodes'
  }
}

export { AUBoundaryNames }
