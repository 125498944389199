import { LoadingButton } from '@mui/lab'
import {
  Avatar,
  Button,
  Dialog,
  DialogProps,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { Check } from '@untitled-ui/icons-react'

function CheckoutSuccess(props) {
  const { open, onClose, ...rest } = props

  return (
    <Dialog
      maxWidth='sm'
      open={open}
      onClose={onClose}
      {...rest}
      sx={(theme) => ({
        p: 3,
        [theme.breakpoints.down(528)]: {
          p: 0
        }
      })}
    >
      <Paper
        component={Stack}
        spacing={2}
        elevation={12}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar
          sx={{
            backgroundColor: 'success.lightest',
            color: 'success.main',
            width: 60,
            height: 60
          }}
        >
          <SvgIcon fontSize='large'>
            <Check />
          </SvgIcon>
        </Avatar>

        <Typography variant='h5'>Thanks for subscribing</Typography>

        <Typography
          align='center'
          color='text.secondary'
          variant='body2'
        >
          A payment to MAPSTACK will appear on your statement, an invoice will
          be sent via email.
        </Typography>
      </Paper>
    </Dialog>
  )
}

export { CheckoutSuccess }
