import {
  Avatar,
  Box,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { DotsHorizontal, User01 } from '@untitled-ui/icons-react/build/esm'
import { sortBy } from 'lodash'
import NextLink from 'next/link'

import { useMemo, useState } from 'react'
import { getUserLink } from 'utils'

import { SeverityPill } from '~ui-components/components/atoms/SeverityPill'
import { DeleteMemberDialog } from '~ui-components/components/molecules/DeleteMemberDialog'
import { usePopover } from '~ui-components/hooks/usePopover'
import { Member_Role } from '~ui-components/types/__generated/gql/graphql'

const MEMBER_TYPES = {
  member: 'member',
  invitedUser: 'invitedUser'
}

const MemberListStack = (props) => {
  const {
    members: membersProp = [],
    invitedUsers = [],
    isDeletingMember = false,
    canDeleteMember = false,
    onDeleteMemberConfirm = () => {},
    onDeleteInvitedUserConfirm = () => {}
  } = props

  const members = useMemo(() => {
    if (!membersProp) return []
    return sortMembersByRole(membersProp)
  }, [membersProp])

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [currentMember, setCurrentMember] = useState(null)
  const [currentMemberType, setCurrentMemberType] = useState(null)

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false)
  }

  const handleDeleteDialogConfirm = async (event) => {
    if (currentMemberType === MEMBER_TYPES.invitedUser) {
      await onDeleteInvitedUserConfirm(event, currentMember)
    } else {
      await onDeleteMemberConfirm(event, currentMember)
    }
    setOpenDeleteDialog(false)
  }

  const handleRemoveUser = (member, type) => {
    setCurrentMember(member)
    setOpenDeleteDialog(true)
    setCurrentMemberType(type)
  }

  return (
    <Box mt={3}>
      {canDeleteMember ? (
        <DeleteMemberDialog
          loading={isDeletingMember}
          open={openDeleteDialog}
          onClose={handleDeleteDialogClose}
          onConfirm={handleDeleteDialogConfirm}
        />
      ) : null}

      <Table sx={{ minWidth: 400 }}>
        <TableHead>
          <TableRow>
            <TableCell>Member</TableCell>
            <TableCell>Role</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member, index) => (
            <TableRow key={index}>
              <TableCell>
                <Stack
                  alignItems='center'
                  direction='row'
                  spacing={1}
                >
                  <Avatar
                    src={member.user.avatar}
                    sx={{ height: 40, width: 40 }}
                  >
                    <SvgIcon>
                      <User01 />
                    </SvgIcon>
                  </Avatar>
                  <div>
                    <Typography
                      component={NextLink}
                      href={getUserLink({
                        firstName: member.user.firstName,
                        lastName: member.user.lastName,
                        userId: member.user.id
                      })}
                      variant='subtitle2'
                      sx={{
                        color: 'text.primary',
                        textDecoration: 'none',
                        ':hover': {
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      {member.user.firstName} {member.user.lastName}
                    </Typography>
                    <Typography
                      color='text.secondary'
                      variant='body2'
                    >
                      {member.user.email}
                    </Typography>
                  </div>
                </Stack>
              </TableCell>
              <TableCell>
                <SeverityPill>{member.role}</SeverityPill>
              </TableCell>
              {canDeleteMember && member.role !== Member_Role.Owner && (
                <TableCell align='right'>
                  <MemberMenu
                    onRemoveClick={() =>
                      handleRemoveUser(member, MEMBER_TYPES.member)
                    }
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
          <InvitedUserList
            invitedUsers={invitedUsers}
            canDeleteMember={canDeleteMember}
            handleRemoveUser={handleRemoveUser}
          />
        </TableBody>
      </Table>
    </Box>
  )
}

export { MemberListStack }

const MemberMenu = ({ onRemoveClick }) => {
  const popover = usePopover<HTMLButtonElement>()

  return (
    <>
      <IconButton
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
      >
        <SvgIcon>
          <DotsHorizontal />
        </SvgIcon>
      </IconButton>
      <Menu
        disableScrollLock
        MenuListProps={{ dense: true }}
        anchorEl={popover.anchorRef.current}
        open={popover.open}
        onClose={popover.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <MenuItem
          onClick={() => {
            onRemoveClick()
            popover.handleClose()
          }}
        >
          <ListItemText>Remove</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

const InvitedUserList = ({
  invitedUsers,
  canDeleteMember,
  handleRemoveUser
}) => {
  return (
    <>
      {invitedUsers.map((invitedUser, index) => {
        return (
          <TableRow key={index}>
            <TableCell>
              <Stack
                alignItems='center'
                direction='row'
                spacing={1}
              >
                <Avatar sx={{ height: 40, width: 40 }}>
                  <SvgIcon>
                    <User01 />
                  </SvgIcon>
                </Avatar>
                <div>
                  <Typography
                    variant='subtitle2'
                    sx={{
                      color: 'text.primary',
                      textDecoration: 'none',
                      ':hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    {invitedUser.email} <b>(PENDING)</b>
                  </Typography>
                  <Typography
                    color='text.secondary'
                    variant='body2'
                  >
                    {invitedUser.email}
                  </Typography>
                </div>
              </Stack>
            </TableCell>
            <TableCell>
              <SeverityPill>{invitedUser.role}</SeverityPill>
            </TableCell>
            {canDeleteMember && (
              <TableCell align='right'>
                <MemberMenu
                  onRemoveClick={() =>
                    handleRemoveUser(invitedUser, MEMBER_TYPES.invitedUser)
                  }
                />
              </TableCell>
            )}
          </TableRow>
        )
      })}
    </>
  )
}

const SORT_BY_ROLES = ['owner', 'admin', 'member']
const sortMembersByRole = (members) => {
  return sortBy(members, (member) => SORT_BY_ROLES.indexOf(member?.role))
}
