import { useRouter } from 'next/router'
import { useState } from 'react'
import { Auth, EmailForm } from 'ui-components'
import { getSignInLink } from 'utils'

function Form() {
  const router = useRouter()
  const [email, setEmail] = useState('')

  const handleChange = (event) => {
    setEmail(event.target.value)
  }

  const handleClick = () => {
    router.push(getSignInLink({ form: 'register', data: { email } }))
  }

  return (
    <EmailForm
      slotProps={{
        EmailInput: {
          onChange: handleChange
        },
        Button: {
          onClick: handleClick
        }
      }}
    />
  )
}

function Email() {
  return <Auth slots={{ Form }} />
}

export { Email }
