import { WorkspaceItem } from 'ui-components'
import { useAuthCurrentWorkspace } from 'user-auth'

export function Workspace() {
  const { data: currentWorkspace } = useAuthCurrentWorkspace()

  if (!currentWorkspace) return null

  return (
    <WorkspaceItem
      workspaceId={currentWorkspace.id}
      workspaceName={currentWorkspace.name}
    />
  )
}
