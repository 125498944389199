import {
  Avatar,
  AvatarGroup,
  Box,
  Skeleton,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const MembersAvatarGroup = (props) => {
  const {
    loading = false,
    position = 'center',
    members: membersProp = [],
    ...rest
  } = props
  const theme = useTheme()

  const members = useMemo(() => {
    const owner =
      membersProp.find((member) => member?.role === 'owner')?.user || {}

    const others = membersProp
      .filter((member) => member?.role !== 'owner')
      .map((member) => member?.user)

    return [owner, ...others]
  }, [membersProp])

  const [owner] = members

  return (
    <Box
      {...rest}
      display='flex'
      flexDirection='column'
      alignItems={positionAt(position)}
    >
      {!loading ? (
        <>
          <Box
            data-testid='MemberAvatarGroupCopy'
            component={Typography}
            fontWeight='bold'
            mb={2}
          >
            {members.length <= 1
              ? `${owner.firstName} has already joined`
              : `${owner.firstName} and ${
                  members.length - 1
                } others have already joined`}
          </Box>
          <Box
            component={AvatarGroup}
            spacing='medium'
            max={6}
            sx={{
              width: '100%',
              '& .MuiAvatar-root': {
                borderWidth: 2,
                borderColor: theme.palette.common.white,
                width: 42,
                height: 42
              },
              '& .MuiAvatar-colorDefault': {
                typography: 'body2',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.main,
                fontWeight: 'bold'
              }
            }}
            justifyContent='center'
          >
            {members.map((member, index) => {
              return (
                <Avatar
                  key={index}
                  data-testid={`MemberAvatar-${index}`}
                  src={member?.avatar ?? ''}
                  alt={[member?.firstName, member?.lastName].join(' ')}
                />
              )
            })}
          </Box>
        </>
      ) : (
        <>
          <Skeleton
            width={300}
            height={42}
            sx={{
              mb: 1
            }}
          />
          <Stack
            direction='row'
            gap={1}
          >
            {Array.from({ length: 3 }).map((_, index) => {
              return (
                <Skeleton
                  key={index}
                  variant='circular'
                  width={42}
                  height={42}
                  sx={{
                    borderRadius: '100%'
                  }}
                />
              )
            })}
          </Stack>
        </>
      )}
    </Box>
  )
}

const MembersAvatarGroupPropTypes = {
  loading: PropTypes.bool,
  members: PropTypes.array,
  position: PropTypes.oneOf(['left', 'center', 'right'])
}

MembersAvatarGroup.propTypes = MembersAvatarGroupPropTypes

export { MembersAvatarGroup, MembersAvatarGroupPropTypes }
export default MembersAvatarGroup

const positionAt = (position) => {
  if (position === 'center') return 'center'
  if (position === 'left') return 'flex-start'
  if (position === 'right') return 'flex-end'
  throw Error('MemberAvatarGroup: passing invalid value to position prop')
}
