import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Typography
} from '@mui/material'
import User03Icon from '@untitled-ui/icons-react/build/esm/User03'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { getUserLink, getWorkspaceLink } from 'utils'

import { RouterLink } from '~ui-components/components/atoms/RouterLink'
import { User, Workspace } from '~ui-components/types/__generated/gql/graphql'

import { Avatar } from '@mui/material'
import type { FC } from 'react'

export type AccountPopoverUser = Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'email'
>

export type AccountPopoverWorkspace = Pick<Workspace, 'id' | 'name' | 'logo'>

interface AccountPopoverProps {
  anchorEl: null | Element
  onClose?: () => void
  open?: boolean
  user?: AccountPopoverUser
  workspaces?: AccountPopoverWorkspace[]
  onLogout?: () => void
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, user, onLogout, workspaces } = props

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      onClose?.()
      onLogout?.()
    } catch (err) {
      console.error(err)
      toast.error('Something went wrong!')
    }
  }, [onClose, onLogout])

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      sx={(theme) => ({
        zIndex: theme.zIndex.modal + 4
      })}
      PaperProps={{ sx: { minWidth: 200, maxWidth: 360 } }}
    >
      {!!user && (
        <Box sx={{ p: 2 }}>
          <Typography variant='body1'>
            {user.firstName} {user.lastName}
          </Typography>
          <Typography
            color='text.secondary'
            variant='body2'
          >
            {user.email}
          </Typography>
        </Box>
      )}

      <Divider />

      {!!user && (
        <Box sx={{ p: 1 }}>
          <ListItemButton
            component={RouterLink}
            href={getUserLink({
              firstName: user.firstName!,
              lastName: user.lastName!,
              userId: user?.id
            })}
            onClick={onClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize='small'>
                <User03Icon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant='body1'>Profile</Typography>}
            />
          </ListItemButton>
        </Box>
      )}

      {!!workspaces?.length && (
        <>
          <Divider />
          <Box sx={{ p: 1 }}>
            {workspaces.map((workspace) => (
              <ListItemButton
                key={workspace.id}
                component={RouterLink}
                href={getWorkspaceLink({
                  workspaceId: workspace.id,
                  workspaceName: workspace.name
                })}
                onClick={onClose}
                sx={{
                  borderRadius: 1,
                  px: 1,
                  py: 0.5
                }}
              >
                <Avatar
                  alt={workspace.name ?? ''}
                  src={workspace.logo ?? ''}
                  sx={{ mr: 1 }}
                />
                <ListItemText
                  primary={
                    <Typography variant='body1'>{workspace.name}</Typography>
                  }
                />
              </ListItemButton>
            ))}
          </Box>
        </>
      )}

      <Divider />

      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center'
        }}
      >
        <Button
          data-testid='AccountPopover-LogoutButton'
          color='inherit'
          onClick={handleLogout}
          size='small'
          variant='text'
        >
          Logout
        </Button>
      </Box>
    </Popover>
  )
}

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
}
