import { SignUpButton as Button } from 'ui-components'
import { useFederatedAuth } from 'user-auth'

export function SignUpButton(props) {
  const { gotoSignInPage } = useFederatedAuth()

  const handleClick = (event) => {
    event.preventDefault()
    gotoSignInPage()
  }

  return (
    <Button
      {...props}
      onClick={handleClick}
    />
  )
}
