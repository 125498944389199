import jscookie from 'js-cookie'
import { WorkspacesListMenu as View } from 'ui-components'
import {
  getWorkspace,
  useAuthCurrentWorkspace,
  useAuthWorkspaces
} from 'user-auth'
import { base64 } from 'utils'

export function Workspaces() {
  const workspaces = useAuthWorkspaces()
  const { data: currentWorkspace } = useAuthCurrentWorkspace()

  if (!workspaces) return null

  const handleClick = (event, workspace) => {
    const payload = getWorkspace(workspace)
    jscookie.set('workspace_current', base64(payload))
  }

  return (
    <View
      workspaces={workspaces}
      currentWorkspace={currentWorkspace}
      onClick={handleClick}
    />
  )
}
