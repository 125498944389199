export const getWorkspace = (workspace) => {
  const {
    id,
    name,
    description,
    logo,
    plan,
    email,
    twitterUsername,
    inviteId,
    stripeCustomerId,
    websiteUrl,
    communityUrl,
    createdAt,
    updatedAt
  } = workspace
  return {
    id,
    name,
    description,
    logo,
    plan,
    email,
    twitterUsername,
    inviteId,
    stripeCustomerId,
    websiteUrl,
    communityUrl,
    createdAt,
    updatedAt
  }
}
