import { CircularProgress, Stack } from '@mui/material'
import { GeocoderHeader } from '~ui-components/components/molecules/GeocoderHeader'

const defaultSlots = {
  GeocoderHeader
}

interface GeocoderAsideProps {
  variant: 'geocode' | 'loading'
  slots?: {
    GeocoderHeader?: typeof GeocoderHeader
  }
  slotProps?: {
    GeocoderHeader?: any
    LoginForm?: any
  }
}

function GeocoderAside(props: GeocoderAsideProps) {
  const { variant, slots, slotProps } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <>
      {variant === 'loading' && (
        <Stack
          sx={{ p: 3, height: '100%' }}
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress />
        </Stack>
      )}

      {variant === 'geocode' && (
        <Stack
          sx={{ height: '100%', maxHeight: '100dvh', overflow: 'hidden' }}
          spacing={3}
          direction='column'
        >
          <Slots.GeocoderHeader
            {...slotProps?.GeocoderHeader}
            sx={{ pt: 3, px: 3 }}
          />
        </Stack>
      )}
    </>
  )
}

export { GeocoderAside }
