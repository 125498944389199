import { QueryClient } from '@tanstack/react-query'
import { fetchAuthSession } from 'aws-amplify/auth/server'
import { GetServerSidePropsContext } from 'next/types'
import {
  getCurrentUserIdKey,
  getCurrentUserTokenKey,
  getUserByIdQuery,
  getUserByIdQueryKey,
  User
} from 'user-auth'
import { runWithAmplifyServerContext, serverClient } from 'web-amplify/server'

import { AuthSession } from 'aws-amplify/auth'
import { getQueryParamsKey } from './router'
import { getIsSSRKey } from './useIsSSR'

type GetServerSidePropsWithClient = (
  context: GetServerSidePropsContext,
  client: QueryClient,
  state: {
    userId: string | null
    user: User | null
    authenticated: boolean
    token: string | null
  }
) => any

export const getProps = (getServerSideProps: GetServerSidePropsWithClient) => {
  return async (context: GetServerSidePropsContext) => {
    const client = new QueryClient()

    await Promise.all([
      client.prefetchQuery({
        queryKey: getQueryParamsKey(),
        queryFn: () => ({ ...context.params, ...context.query })
      }),
      client.prefetchQuery({
        queryKey: getIsSSRKey(),
        queryFn: () => true
      })
    ])

    const props = await runWithAmplifyServerContext({
      nextServerContext: { request: context.req, response: context.res },
      operation: async (ctx) => {
        let session: AuthSession

        try {
          session = await fetchAuthSession(ctx)
        } catch (error) {
          console.error(error)
          return {
            userId: null,
            user: null,
            authenticated: false,
            token: null,
            status: 'pending'
          }
        }

        const token = session?.tokens?.idToken?.toString() || null
        const id = session?.tokens?.idToken?.payload['custom:id'] as string

        if (!id)
          return {
            userId: null,
            user: null,
            authenticated: false,
            token: null
          }

        await client.prefetchQuery({
          queryKey: getCurrentUserIdKey(),
          queryFn: () => id
        })

        const { data } = (await serverClient.graphql(ctx, {
          query: getUserByIdQuery,
          variables: { id },
          authMode: 'apiKey'
        })) as any

        const user = data?.getUserById

        if (user)
          await client.prefetchQuery({
            queryKey: getUserByIdQueryKey(id),
            queryFn: () => user
          })

        await client.prefetchQuery({
          queryKey: getCurrentUserTokenKey(),
          queryFn: () => token
        })

        return {
          userId: id,
          user: user,
          authenticated: true,
          token,
          status: 'success'
        }
      }
    })

    const serverProps = await getServerSideProps(context, client, props)

    serverProps['props'] = {
      ...serverProps['props'],
      authState: props
    }

    return serverProps
  }
}
