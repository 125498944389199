import { dehydrate } from '@tanstack/query-core'
import { serialize } from 'cookie'
import { getProps } from 'ssr/getProps'
import { base64, fromBase64, getMapLink, getSignInLink } from 'utils'
import { createRunner, serverClient } from 'web-amplify/server'

import { canUserAccessMapQuery } from '~map-viewer/api/canUserAccessMap'
import { getMapByIdQuery } from '~map-viewer/api/getMapById'
import { getWorkspaceByMapIdQuery } from '~map-viewer/api/getWorkspaceByMapId'
import { generateOpenGraph } from '../generateOpenGraph'

type Params = {
  mapId: string
  rest: string[]
  hashed: string
}

export const getServerSideProps = getProps(async (context, client, props) => {
  const { token, authenticated, userId } = props
  const { params, req, query } = context || {}
  const { mapId, rest, hashed } = params as Params
  const { referer, host } = req.headers || {}
  const { embedKey } = query

  const [mapName, visualisation] = rest || []

  if (!mapId) return { notFound: true }

  const run = createRunner(context)

  const workspace = (await run(async (context) => {
    const { data } = (await serverClient.graphql(context, {
      query: getWorkspaceByMapIdQuery,
      variables: {
        id: mapId
      },
      authMode: 'apiKey'
    })) as any

    return data?.getWorkspaceByMapId
  })) as any

  const canAccess = await run(async (context) => {
    const { data } = (await serverClient.graphql(context, {
      query: canUserAccessMapQuery,
      ...(authenticated
        ? {
            variables: {
              mapId
            },
            authMode: 'userPool',
            authToken: token as string
          }
        : {
            variables: {
              mapId
            },
            authMode: 'apiKey'
          })
    })) as any

    return data?.canUserAccessMap
  })

  if (!authenticated && !canAccess) {
    const cookie = [
      serialize(
        'auth_metadata',
        base64({
          pathname: '/map/[mapId]/[...rest]',
          redirect: getMapLink({ mapId, mapName: mapName! }),
          data: {
            workspace
          }
        }),
        {
          maxAge: 3600,
          path: '/sign-in'
        }
      )
    ]

    context.res.setHeader('Set-Cookie', cookie)

    return {
      redirect: {
        destination: getSignInLink(),
        statusCode: 307
      }
    }
  }

  if (authenticated && !canAccess) {
    return {
      props: {
        dehydratedState: dehydrate(client),
        authorized: false
      }
    }
  }

  const map = await run(async (context) => {
    const { data } = (await serverClient.graphql(context, {
      query: getMapByIdQuery,
      variables: {
        id: mapId,
        embedKey
      },
      ...(authenticated
        ? { authMode: 'userPool', authToken: token as string }
        : {
            authMode: 'apiKey'
          })
    })) as any

    const { readme, ...map } = data.getMapById
    if (readme) readme.nodes = readme.nodes.map((node) => JSON.parse(node))
    return { ...map, readme }
  })

  const og = generateOpenGraph(host, map as any, visualisation)
  const shareState = fromBase64(hashed) || {}

  return {
    props: {
      dehydratedState: dehydrate(client),
      mapId,
      userId,
      shareState,
      og,
      map,
      embedKey: embedKey ?? null,
      authorized: true
    }
  }
})
