import { sendGTMEvent, sendGAEvent } from '@next/third-parties/google'

export const gtag = function (
  type: string,
  event: string,
  options: object = {}
) {
  sendGTMEvent({ event, ...options })
  // INFO: This line if you want to direct send GA events
  sendGAEvent(type, event, options)
}
