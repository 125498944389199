import { Hub } from 'aws-amplify/utils'
import jscookie from 'js-cookie'
import type { FC, ReactNode } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { base64, fromBase64, useLayoutEffect } from 'utils'

import { getWorkspace } from '~user-auth/functions/getWorkspace'
import {
  useGetCurrentUserId,
  useGetCurrentUserToken,
  useGetUserById
} from '~user-auth/queries/useGetUserById'
import { User } from '~user-auth/types/__generated/gql/graphql'

export type AuthState = {
  status: string | 'idle' | 'loading' | 'ready'
  initialized: boolean
  loading: boolean
  authenticated: boolean
  ready: boolean
  userId?: string
  user?: User
  token: string | null
  isStaff: boolean
}

export const initialState: AuthState = {
  status: 'idle',
  initialized: false,
  loading: false,
  authenticated: false,
  ready: false,
  user: undefined,
  token: null,
  isStaff: false
}

export type AuthContext = AuthState & {
  setAuth: (auth: Partial<AuthState>) => void
  resetAuth: () => void
}

export const AuthContext = createContext<AuthContext>({
  ...initialState,
  setAuth: () => {},
  resetAuth: () => {}
})

export const AuthProvider: FC<{
  state: any
  children: ReactNode
}> = (props) => {
  const { state: stateProps, children } = props

  const { data: currentId } = useGetCurrentUserId()
  const { data: token } = useGetCurrentUserToken()
  const { data: user, status } = useGetUserById(currentId)

  const getState = (state) => {
    const { status, user, token } = state || {}
    return {
      ...(status === 'pending'
        ? { status: 'loading', loading: true, ready: false }
        : {}),

      ...(status === 'error'
        ? {
            status: 'ready',
            initialized: true,
            loading: false,
            ready: true
          }
        : {}),

      ...(status === 'success'
        ? {
            userId: user?.id,
            status: 'ready',
            authenticated: true,
            initialized: true,
            loading: false,
            ready: true,
            user,
            token,
            isStaff:
              !!user?.email &&
              ['@mangomap.com', '@mapstack.io'].some((domain) =>
                user.email.includes(domain)
              )
          }
        : {})
    }
  }

  const [state, setState] = useState<AuthState>({
    ...initialState,
    ...getState(stateProps)
  })

  const setAuth = useCallback<AuthContext['setAuth']>((auth) => {
    setState((prevState) => {
      const combined = { ...prevState, ...auth }

      const currentWorkspace = getCurrentWorkspaceCache()

      if (!currentWorkspace && combined.user?.defaultWorkspace) {
        jscookie.set(
          'workspace_current',
          base64(getWorkspace(combined.user.defaultWorkspace))
        )
      }

      return combined
    })
  }, [])

  const resetAuth = useCallback<AuthContext['resetAuth']>(() => {
    setState(initialState)
  }, [])

  useLayoutEffect(() => {
    setAuth(getState({ status, token, user }))
  }, [setAuth, token, user, status])

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signedOut': {
          setAuth(initialState)
          break
        }

        default:
        // do nothing
      }
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        setAuth,
        resetAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const getCurrentWorkspaceCache = () =>
  fromBase64(jscookie.get('workspace_current'))

export const useAuth = () => useContext(AuthContext)
