import { useAuth } from '~user-auth/contexts/auth'
import { Workspace } from '~user-auth/types/__generated/gql/graphql'

export type AccountWorkspace = Pick<
  Workspace,
  | 'id'
  | 'description'
  | 'createdAt'
  | 'name'
  | 'logo'
  | 'memberCollection'
  | 'guestCollection'
>

export const useAuthWorkspaces = (): AccountWorkspace[] => {
  const { user } = useAuth()

  const workspaces = user?.membershipCollection?.items?.map(
    (item) => item.workspace
  )

  return workspaces as AccountWorkspace[]
}
