import { Header } from 'header'
import { Home as Layout, Seo } from 'ui-components'

interface HomeProps {
  seoProps?: React.ComponentProps<typeof Seo>
}

const Home = (props: HomeProps) => {
  return (
    <>
      <Seo
        title='Mapstack: More Than Geocoding—Visualize Location Data Instantly'
        description='Mapstack transforms your address data into stunning, interactive maps. Instantly visualize location data, uncover hidden insights, and make smarter decisions with ease.'
        openGraph={{
          title:
            'Mapstack: More Than Geocoding—Visualize Location Data Instantly',
          description:
            'Mapstack makes it simple to turn your spreadsheets into stunning, interactive maps. Instantly visualize location data, uncover insights, and make smarter decisions effortlessly.',
          images: [
            {
              url: 'https://mapstack.io/images/og_image.webp',
              secureUrl: 'https://mapstack.io/images/og_image.webp',
              alt: 'Mapstack - Visualize and Map Location Data Instantly',
              type: 'image/webp',
              width: 1200,
              height: 630
            }
          ],
          url: 'https://mapstack.io/',
          siteName: 'Mapstack'
        }}
        {...props?.seoProps}
      />

      <Layout
        slots={{
          Header
        }}
      />
    </>
  )
}

export { Home }
