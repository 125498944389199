import { LoadingButton } from '@mui/lab'
import {
  MenuItem,
  menuItemClasses,
  Select,
  Stack,
  Typography
} from '@mui/material'
import * as yup from 'yup'

import { BrandLogo } from '~ui-components/components/atoms/BrandLogo'

export const mapAccessApproveSchema = yup.object({
  role: yup.string().required().oneOf(['member', 'guest'])
})

export type MapAccessApproveForm = {
  role: 'member' | 'guest'
}

type MapAccessApproveProps = {
  form: any
  user: any
  map: any
  slotProps?: any
}

export function MapAccessApprove(props: MapAccessApproveProps) {
  const { user = {}, map = {}, form, slotProps } = props

  const fullname = `${user?.firstName} ${user?.lastName}`

  return (
    <Stack
      direction='column'
      spacing={4}
      alignItems='center'
      justifyContent='center'
      minHeight='100vh'
      px={2}
      mt='-80px'
    >
      <BrandLogo
        size='xl'
        variant='logoCopy'
      />
      <Typography
        variant='next:h6'
        textAlign='center'
      >
        <b>{fullname}</b> has requested access <b>{map?.name}</b>
      </Typography>

      <Stack
        component='form'
        direction='column'
        spacing={2}
        onSubmit={form?.handleSubmit}
      >
        <Select
          MenuProps={{
            sx: {
              [`.${menuItemClasses.root}`]: {
                mx: 1,
                borderRadius: 1
              }
            }
          }}
          {...form.getFieldProps('role')}
        >
          <MenuItem value='member'>Member</MenuItem>
          <MenuItem value='guest'>Guest</MenuItem>
        </Select>

        <Stack
          direction='row'
          gap={2}
        >
          <LoadingButton
            type='submit'
            size='large'
            variant='contained'
            color='primary'
            disableElevation
            {...slotProps?.SubmitButton}
          >
            Approve
          </LoadingButton>
          <LoadingButton
            size='large'
            color='inherit'
            disableElevation
            {...slotProps?.DenyButton}
          >
            Deny
          </LoadingButton>
        </Stack>
      </Stack>

      <Stack
        direction='column'
        textAlign='center'
      >
        <Typography
          variant='next:subtitle2'
          maxWidth='37ch'
        >
          Make sure you trust the external user before sharing any content.
        </Typography>
      </Stack>
    </Stack>
  )
}
