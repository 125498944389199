import jscookie from 'js-cookie'
import { useRouter } from 'next/router'
import { signUpEvent } from 'tag-manager/sign_up'
import { LoginForm } from 'ui-components'
import { getSignInLink } from 'utils'

import { useAuthMetadata } from '~user-auth/hooks/useAuthMetadata'
import { useFederatedAuth } from '~user-auth/hooks/useFederatedAuth'

import { Header } from '../Header'

function Login() {
  const router = useRouter()

  const { signIn } = useFederatedAuth()
  const { redirect } = useAuthMetadata()

  const callback = router.query?.callback
  const disabled = callback === 'true'

  const clearAuthCookie = () => {
    jscookie.remove('auth_metadata')
  }

  const handleGoogleMethod = () => {
    signUpEvent()
    clearAuthCookie()
    signIn({ provider: 'Google', redirect })
  }

  const handleFacebookMethod = () => {
    signUpEvent()
    clearAuthCookie()
    signIn({ provider: 'Facebook', redirect })
  }

  const handleEmailMethod = () => {
    signUpEvent()
    clearAuthCookie()
    router.push(getSignInLink({ form: 'email' }))
  }

  return (
    <LoginForm
      slots={{ Header }}
      slotProps={{
        GoogleButton: {
          disabled,
          onClick: handleGoogleMethod
        },
        FacebookButton: {
          disabled,
          onClick: handleFacebookMethod
        },
        EmailButton: {
          disabled,
          onClick: handleEmailMethod
        }
      }}
    />
  )
}

export { Login }
