import { Box, Container, Paper, Stack, Typography } from '@mui/material'
import NextImage from 'next/image'

import { CTAButtons } from './CTAButtons'
import image1 from './section-2-1.png'
import image2 from './section-2-2.png'

export function Section2() {
  return (
    <Box
      component={Container}
      py={8}
    >
      <Stack
        component={Paper}
        spacing={6}
        alignItems='center'
        elevation={8}
        sx={{
          p: {
            xs: 4,
            sm: 6
          },
          borderRadius: 4
        }}
      >
        <Typography
          component='h2'
          variant='next:h2'
          fontWeight={700}
          textAlign='center'
        >
          From data to insights.{' '}
          <Typography
            component='i'
            variant='inherit'
            fontStyle='italic'
            fontWeight={200}
          >
            faster!
          </Typography>
        </Typography>

        <Typography
          component='p'
          variant='next:h6'
          textAlign='center'
          color='text.secondary'
          maxWidth='88%'
        >
          Remove wasted time geocoding your location data or manual analysis.
          Enable you and your team to visualise your data to get insights
          quickly without cumbersome and expensive GIS tools
        </Typography>

        <HighlightCard
          image={image1}
          alt='Simplify your workflow'
          title='Simplify your workflow'
          description='Stop juggling multiple tools. Mapstack combines geocoding and interactive mapping in a single platform, saving you time and simplifying your workflow.'
        />

        <HighlightCard
          direction='row-reverse'
          image={image2}
          alt='No development skills needed'
          title='No development skills needed'
          description={
            <>
              Create your maps with ease with no development knowledge, API
              integrations or mapping experts required.
              <Box py={1} />
              Simply upload or connect your data and see it instantly on an
              interactive map.
            </>
          }
        />

        <Typography
          component='h3'
          variant='next:h3'
          fontWeight={700}
          textAlign='center'
        >
          Fixed price and unlimited geocodes
        </Typography>

        <Typography
          component='p'
          variant='next:h6'
          textAlign='center'
          color='text.secondary'
          maxWidth='88%'
        >
          Low cost and predictable pricing to suit your needs. Easily get
          signoff
        </Typography>

        <CTAButtons />
      </Stack>
    </Box>
  )
}

function HighlightCard(props) {
  const { direction = 'row', image, alt, title, description } = props
  return (
    <Stack
      direction={direction}
      flexWrap='wrap'
      gap={8}
      justifyContent='center'
    >
      <Box
        sx={{
          position: 'relative',
          aspectRatio: '1/1',
          width: '100%',
          flex: 1,
          maxWidth: 400,
          maxHeight: 400,
          flexBasis: 'clamp(100%, 400px, 100%)'
        }}
      >
        <NextImage
          src={image}
          fill
          alt={alt}
        />
      </Box>
      <Stack
        flex={1}
        flexBasis='30ch'
        spacing={2}
        justifyContent='center'
      >
        <Typography variant='h2'>{title}</Typography>
        <Typography
          color='text.secondary'
          variant='next:h6'
          letterSpacing='tight'
        >
          {description}
        </Typography>
      </Stack>
    </Stack>
  )
}
