import { Card, Tab, Tabs } from '@mui/material'
import { FC } from 'react'

const defaultSlots = {
  MemberAccessTab: () => null,
  MembersTabTools: () => null,
  MemberList: () => null,
  GuestList: () => null
}

export enum PEOPLE_TAB {
  MEMBERS = 'members',
  GUESTS = 'guests'
}

type MembersTabProps = {
  currentTab: PEOPLE_TAB
  slots: Record<keyof typeof defaultSlots, FC>
  slotProps?: Record<'membersTabTools' | 'memberList' | 'guestList', any>
}

export const MembersTab: FC<MembersTabProps> = ({
  currentTab,
  slots,
  slotProps
}) => {
  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Card>
      <Slots.MemberAccessTab />

      {currentTab === PEOPLE_TAB.MEMBERS && (
        <>
          <Slots.MembersTabTools {...slotProps?.membersTabTools} />
          <Slots.MemberList {...slotProps?.memberList} />
        </>
      )}

      {currentTab === PEOPLE_TAB.GUESTS && (
        <Slots.GuestList {...slotProps?.guestList} />
      )}
    </Card>
  )
}

export function MemberAccessTab(props) {
  const { currentTab, membersCount, guestsCount, onTabChange } = props

  return (
    <Tabs
      indicatorColor='primary'
      onChange={onTabChange}
      scrollButtons='auto'
      sx={{ px: 3 }}
      textColor='primary'
      value={currentTab}
      variant='scrollable'
    >
      <Tab
        label={`Members (${membersCount})`}
        value='members'
      />
      <Tab
        label={`Guests (${guestsCount})`}
        value='guests'
      />
    </Tabs>
  )
}
