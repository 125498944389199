import * as qs from 'qs'

type Options = Record<string, any>

function getSignInLink(options?: Options) {
  const query = qs.stringify(options)
  return `/sign-in${query ? `?${query}` : ''}`
}

export { getSignInLink }
