import { Button, Link, Typography } from '@mui/material'
import dynamic from 'next/dynamic'

const MuiOtpInput = dynamic(
  () => import('mui-one-time-password-input').then((mod) => mod.MuiOtpInput),
  {
    ssr: false,
    loading: () => null
  }
)

import { Fragment } from 'react'
import { AuthFormHeader } from '../FormHeader'

interface VerifyFormProps {
  email?: string
  slots?: {
    Form?: React.ElementType
    OtpInput?: React.ElementType
    Button?: React.ElementType
    ResendLink?: React.ElementType
  }
  slotProps?: {
    Form?: any
    OtpInput?: any
    Button?: any
    ResendLink?: any
  }
}

function VerifyForm(props: VerifyFormProps) {
  const { email, slots, slotProps } = props

  const Slots = {
    Form: Fragment,
    OtpInput: MuiOtpInput,
    ...slots
  }

  return (
    <>
      <AuthFormHeader
        title='Finish logging in'
        subtitle={`Once you enter the code we sent to ${email}, you'll be all logged`}
      />

      <Slots.Form {...slotProps?.Form}>
        <Slots.OtpInput
          length={6}
          sx={{
            '& .MuiFilledInput-input': {
              p: '18px'
            }
          }}
          {...slotProps?.OtpInput}
        />

        <Button
          variant='contained'
          type='submit'
          size='large'
          sx={{
            borderRadius: 99
          }}
          {...slotProps?.Button}
        >
          Continue
        </Button>
      </Slots.Form>

      <Typography variant='caption'>
        Didn&apos;t get the code?&nbsp;
        <Link
          sx={{
            cursor: 'pointer'
          }}
          color='secondary'
          {...slotProps?.ResendLink}
        >
          Resend code
        </Link>
      </Typography>
    </>
  )
}

export { VerifyForm }
