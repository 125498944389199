import { getWorkspaceLink } from 'utils'
import { AvatarProfile } from '~ui-components/components/molecules/AvatarProfile'
import { Workspace } from '~ui-components/types/__generated/gql/graphql'

interface AvatarProfileProps {
  workspace: Workspace
}

function MapMetadataProfile(props: AvatarProfileProps) {
  const { workspace } = props

  return (
    <AvatarProfile
      alt={workspace?.name}
      src={workspace?.logo ?? ''}
      href={getWorkspaceLink({
        workspaceId: workspace?.id,
        workspaceName: workspace?.name
      })}
      primaryText={workspace?.name}
    />
  )
}

export { MapMetadataProfile }
