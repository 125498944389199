import { graphql } from 'ms-client/graphql/user-pool'

export const canUserAccessMap = async (mapId) => {
  return await graphql({
    query: canUserAccessMapQuery,
    variables: {
      mapId
    }
  })
}

export const canUserAccessMapQuery = /*GraphQL */ `
query CanUserAccessMap($mapId: ID!) {
  canUserAccessMap(mapId: $mapId) 
}
`
