const Status = {
  Idle: 'idle',
  Failed: 'failed',
  Uploading: 'uploading',
  Uploaded: 'uploaded',
  Ready: 'ready'
}

const url = (cloudfront, path) => `${cloudfront}/${path}`

const getReadmeAssetsUrl = (element) => {
  switch (element.status) {
    case Status.Ready:
      return url(process.env.NEXT_PUBLIC_MAP_DATA_URL, element.path)

    case Status.Uploaded:
      return url(
        process.env.NEXT_PUBLIC_README_ASSETS_TEMP_CLOUDFRONT,
        element.path
      )

    default:
      return element.path
  }
}

export { getReadmeAssetsUrl, Status }
