import { AuthFormHeader } from 'ui-components'
import { useAuthMetadata } from '~user-auth/hooks/useAuthMetadata'

export function Header(props) {
  const { data, pathname } = useAuthMetadata()

  if (matchMapLink(pathname)) {
    return (
      <AuthFormHeader
        title={`Sign in to view this map on ${data.workspace.name}`}
        subtitle={undefined}
      />
    )
  }

  return <AuthFormHeader {...props} />
}

const matchMapLink = (link: string) => link === '/map/[mapId]/[...rest]'
