// @ts-nocheck
import { useEffect, useState } from 'react'
import { CookiesSnack as Snack } from 'ui-components'
import { useAuth } from 'user-auth'

const initialGtag = () => {
  if (typeof window === 'undefined') return

  window.dataLayer = window.dataLayer || []
  window.gtag = function () {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments)
  }
  // Default ad_storage to 'denied'.
  gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    wait_for_update: 500
  })
}

export function Cookies(props) {
  initialGtag()
  const { children } = props
  const { user, authenticated } = useAuth()

  const [client, setClient] = useState(false)
  const [scroll, setScroll] = useState(false)
  const [open, setOpen] = useState(
    typeof window === 'undefined'
      ? false
      : localStorage.getItem('cookies:accepted') === null
  )

  const handleClose = () => {
    localStorage.setItem('cookies:accepted', String(false))
    setOpen(false)
  }

  const handleAccept = () => {
    localStorage.setItem('cookies:accepted', String(true))
    if (window && window.gtag) {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted'
      })
    }
    setOpen(false)
  }

  useEffect(() => setClient(true), [])

  useEffect(() => {
    if (authenticated) {
      if (window && window.gtag) {
        window.gtag('set', {
          user_id: user?.id,
          user_properties: {
            workspace_id: user?.defaultWorkspaceId
          }
        })
      }
    }
  }, [authenticated])

  useEffect(() => {
    const isGranted = localStorage.getItem('cookies:accepted') === 'true'
    if (isGranted) {
      if (window && window.gtag) {
        window.gtag('consent', 'update', {
          ad_storage: 'granted',
          analytics_storage: 'granted',
          ad_user_data: 'granted',
          ad_personalization: 'granted'
        })
      }
    }
  }, [])

  return (
    <>
      {children}
      {client && (
        <Snack
          open={open}
          onAccept={handleAccept}
          onClose={handleClose}
        />
      )}
    </>
  )
}
