import { Divider, Stack, Typography } from '@mui/material'
import { getWorkspaceLink } from 'utils'
import MapstackLogoCopy from '~ui-components/components/atoms/BrandLogo/LogoCopy'
import { AvatarProfile } from '~ui-components/components/molecules/AvatarProfile'
import { MapDataSettingsButton } from '~ui-components/components/molecules/MapDataSettingsButton'
import { MapRemixButton } from '~ui-components/components/molecules/MapRemixButton'
import { MapStarButton } from '~ui-components/components/molecules/MapStarButton'
import { Sidebar } from '~ui-components/components/molecules/Sidebar'
import { TextEditor } from '~ui-components/components/molecules/TextEditor'
import { Workspace } from '~ui-components/types/__generated/gql/graphql'

const Empty = () => null

const defaultSlots = {
  MapDescriptionTitle,
  MapDescriptionWorkspaceLink,
  MapDescriptionTags: Empty,
  MapRemixButton,
  MapStarButton,
  MapDataSettingsButton,
  MapDescriptionEditor: TextEditor
}

function MapDescriptionDrawer(props) {
  const { open, onClose, slots, slotProps, isEmbed = false } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  return (
    <Sidebar
      isEmbed={isEmbed}
      anchor='left'
      icon={<MapstackLogoCopy size='sm' />}
      open={open}
      onClose={onClose}
      ModalProps={{
        disablePortal: true,
        hideBackdrop: true,
        sx: {
          top: 'unset',
          right: 'unset',
          bottom: 'unset',
          left: 'unset'
        }
      }}
      PaperProps={{
        sx: {
          overflowY: 'hidden'
        }
      }}
    >
      <Stack
        flex={1}
        spacing={2}
        p={2}
        direction='column'
      >
        <Slots.MapDescriptionTitle {...slotProps?.MapDescriptionTitle} />

        <Slots.MapDescriptionWorkspaceLink
          {...slotProps?.MapDescriptionWorkspaceLink}
        />

        <Slots.MapDescriptionTags {...slotProps?.MapDescriptionTags} />

        <Divider sx={{ mx: (theme) => `-${theme.spacing(2)} !important` }} />

        <Stack
          direction='row'
          spacing={2}
        >
          <Slots.MapRemixButton {...slotProps?.MapRemixButton} />
          <Slots.MapStarButton {...slotProps?.MapStarButton} />
          <Slots.MapDataSettingsButton {...slotProps?.MapDataSettingsButton} />
        </Stack>

        <Divider sx={{ mx: (theme) => `-${theme.spacing(2)} !important` }} />

        <Slots.MapDescriptionEditor
          {...slotProps?.MapDescriptionEditor}
          sx={{
            flex: 1,
            p: 2,
            mx: (theme) => `${theme.spacing(-2)} !important`,
            mt: (theme) => `${theme.spacing(0)} !important`,
            mb: (theme) => `${theme.spacing(-2)} !important`
          }}
        />
      </Stack>
    </Sidebar>
  )
}

function MapDescriptionTitle(props) {
  const { title } = props
  return (
    <Typography
      component='h1'
      variant='h5'
      whiteSpace='wrap'
      lineHeight={1.3}
    >
      {title}
    </Typography>
  )
}

function MapDescriptionWorkspaceLink(props: { workspace: Workspace }) {
  const { workspace } = props
  return (
    <AvatarProfile
      alt={workspace.name ?? ''}
      src={workspace.logo ?? ''}
      href={getWorkspaceLink({
        workspaceId: workspace?.id,
        workspaceName: workspace?.name
      })}
      primaryText={workspace?.name}
    />
  )
}

export {
  MapDescriptionDrawer,
  MapDescriptionTitle,
  MapDescriptionWorkspaceLink
}
