import { useQuery } from '@tanstack/react-query'
import jscookie from 'js-cookie'
import { useRouter } from 'next/router'
import { base64, fromBase64 } from 'utils'

import { useAuth } from '~user-auth/contexts/auth'
import { getWorkspace } from '~user-auth/functions/getWorkspace'

export const getWorkspaceByIdQuery = /* GraphQL */ `
  query GetWorkspaceById($id: ID!) {
    getWorkspaceById(id: $id) {
      id
      name
      description
      logo
      plan
      email
      twitterUsername
      inviteId
      stripeCustomerId
      websiteUrl
      communityUrl
      createdAt
      updatedAt
    }
  }
`

const getCurrentWorkspaceCacheKey = (workspaceId: string) => [
  'getCurrentWorkspaceCache',
  workspaceId
]

export const useAuthCurrentWorkspace = () => {
  const { ready, user } = useAuth()
  const router = useRouter()
  const workspaceId = String(router.query?.workspaceId || '')

  const query = useQuery({
    enabled: ready,
    queryKey: getCurrentWorkspaceCacheKey(workspaceId),
    queryFn: async () => {
      /**
       * if workspaceId is defined means we are on workspace page, so we cached current workspace by that workspaceId
       * if we are not on workspace page, we get it from the cache
       * if we don't have any cached workspace, we get the default workspace
       */
      let workspace

      if (workspaceId) {
        workspace = user?.membershipCollection?.items
          ?.map((item) => item.workspace)
          .find((workspace) => workspace.id === workspaceId)

        if (!workspace) return undefined

        jscookie.set('workspace_current', base64(getWorkspace(workspace)))

        return workspace
      }

      const cached = fromBase64(jscookie.get('workspace_current') || '{}')

      if (cached) {
        const workspace = user?.membershipCollection?.items.find(
          (item) => item.workspace.id === cached.id
        )?.workspace

        if (workspace) return workspace
      }

      workspace = user?.membershipCollection?.items?.find(
        (workspace) => workspace.id === user?.defaultWorkspaceId
      )

      if (!workspace) return undefined

      jscookie.set('workspace_current', base64(getWorkspace(workspace)))

      return workspace
    }
  })

  return query
}
