import { Button, Stack } from '@mui/material'
import NextLink from 'next/link'
import { getSignInLink } from 'utils'

export function CTAButtons() {
  const ctaLink =
    'https://mapstack.io/workspace/dQjbj6/mapstack-explorer?tab=maps'
  return (
    <Stack
      direction='row'
      gap={2}
    >
      <Button
        component={NextLink}
        href={getSignInLink()}
        variant='contained'
        color='primary'
        size='large'
      >
        Create your map
      </Button>

      <Button
        component={NextLink}
        href={ctaLink}
        variant='text'
        color='inherit'
        size='large'
      >
        See some examples
      </Button>
    </Stack>
  )
}
