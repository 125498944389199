import axios from 'axios'
import Pbf from 'pbf'
import pako from 'pako'
import geobuf from './libs/geobuf'

const fetchGeoJson = async (path) => {
  const endpoint = `${process.env.NEXT_PUBLIC_MAP_DATA_URL}/${path}/output.pbf`
  const response = await axios.get(endpoint, {
    responseType: 'arraybuffer'
  })

  const pbf = new Pbf(response.data)
  const geojson = geobuf.decode(pbf)
  return geojson
}

const fetchJson = async (path) => {
  const endpoint = `${process.env.NEXT_PUBLIC_MAP_DATA_URL}/${path}/output.json`
  const response = await axios.get(endpoint, {
    decompress: true,
    responseType: 'arraybuffer',
    headers: {
      'Content-Encoding': 'gzip'
    }
  })

  const text = pako.ungzip(response.data, { to: 'string' })
  return JSON.parse(text)
}

const fetchMbtiles = async (path) => {
  const endpoint = `${process.env.NEXT_PUBLIC_MAP_DATA_URL}/${path}/output.mbtiles`
  const response = await axios.get(endpoint)
  return response.data
}

export default async (path, format) => {
  const actions = {
    geojson: fetchGeoJson,
    json: fetchJson,
    mbtiles: fetchMbtiles
  }

  try {
    return await actions[format](path)
  } catch (e) {
    console.error('Invalid format')
    throw e
  }
}
