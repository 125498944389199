import { Stack } from '@mui/material'
import { Footer } from '~ui-components/components/organisms/Footer'

export const PageLayout = (props) => {
  const { slots: Slots, slotProps, children } = props

  return (
    <>
      <Slots.Header {...slotProps?.Header} />

      <Stack
        component='main'
        sx={{ pt: '64px' }}
      >
        {children}
      </Stack>

      <Footer />
    </>
  )
}
