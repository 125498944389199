import { Stack, Typography } from '@mui/material'
import NextLink from 'next/link'
import { BrandLogo } from '~ui-components/components/atoms/BrandLogo'

interface FormHeaderProps {
  title?: string
  subtitle?: string
}

export function AuthFormHeader(props: FormHeaderProps) {
  return (
    <Stack gap={3}>
      <NextLink href='/'>
        <BrandLogo
          variant='logoCopy'
          size='lg'
        />
      </NextLink>

      <Typography variant='h5'>{props.title}</Typography>
      {props.subtitle && (
        <Typography variant='subtitle1'>{props.subtitle}</Typography>
      )}
    </Stack>
  )
}
